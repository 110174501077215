#top
  margin-top: -100px
  padding-top: 80px
  width: 100%
  height: auto
  min-height: 100vh
  +v-lg
    padding: 80px 0 0
    min-height: auto
  +v-xl
    padding: 200px 0 40px
    min-height: auto
  +v-sm
    margin-top: -100px
    min-width: 100% !important
    padding: 200px 0 40px !important
    min-height: auto
  .button
    &.mx-4
      +v-sm
        margin-left: 0 !important
        margin-right: 0 !important
    +v-xs
      max-width: 86%
      margin-left: auto
      margin-right: auto
