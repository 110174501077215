html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $dark
  font-size: 95%
  font-family: $basic
  font-weight: 500
  font-smooth: always
  letter-spacing: .03em
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $body

a, * a
  color: inherit !important
  text-decoration: none !important

.page-wrapper
  width: 60%
  margin-left: auto
  margin-right: auto
  +v-sm
    width: 90%

li
  padding: 8px 0

footer
  display: flex
  justify-content: center
  align-items: flex-end
  width: 100%
  +v-sm
    display: block
    padding: 20px
    height: auto
    min-height: auto !important
    text-align: center !important
  img
    transition: all ease-out .3s
    &:hover
      opacity: .5

#faq
  .w-80
    +v-xs
      min-width: 100% !important

#contact
  a
    &:hover
      color: darken($theme, 10%) !important
    &.c-light
      margin-left: 20px
      +v-sm
        margin-left: 20px
        margin-right: 20px
      +v-xs
        display: inline-block
        width: 50%
        margin-left: auto
        margin-right: auto
        margin-top: 20px

img
  +v-sm
    width: auto !important
    margin-left: auto !important
    margin-right: auto !important

.s-100
  +v-sm
    display: none !important
    height: 0 !important
.s-100
  +v-xs
    display: none !important
    height: 0 !important

.s-m-40
  display: none
  +v-sm
    display: block
    height: 40px

.table-mob
  td,
  th
    padding: 0 40px 10px
    +v-sm
      padding: 0 5%
    +v-xs
      zoom: .75
      padding: 0 5%
      transform: translateY(-40px)

header
  +v-sm
    z-index: 9999999
  a
    color: $medium !important
    font-weight: 500
    transition: all ease-out .3s
    &:hover
      color: darken($theme, 10%) !important
  +v-sm
    position: fixed
    top: -20px
    left: 0
    width: 100%
    height: 80px
    max-height: 80px !important
    padding: 0 24px !important
    margin: 0 !important
    border-bottom: 1px solid $light
    background-color: $white
  #logo
    +v-sm
      transform: translate(-6px,14px)
    +v-xs
      max-width: 120px
  .menu
    display: flex
    justify-content: flex-end
    align-items: center
    &.active
      background-color: transparent !important
    +v-sm
      position: fixed
      display: block
      top: 76px
      left: 0
      margin: 0 !important
      padding: 0 !important
      width: 100%
      height: 100vh
      min-height: 100vh
      overflow: hidden
      background-color: #fff
      z-index: 999 !important
      overflow-x: hidden
      overflow-x: hidden
      transition: left ease-out .5s
      &.active
        left: 120%
      a
        display: flex
        align-items: center
        width: 100%
        height: 60px
        padding: 0 24px !important
        border-top: 1px solid $light
        transform: translateX(-10px)
        &.social
          background-color: $light
          transform: translateX(-16px)

section
  display: block
  +v-sm
    position: relative

.burger
  display: none
  +v-sm
    position: fixed
    top: 30px
    right: 50px
    display: block
    span
      width: 24px
      height: 2px
      background-color: $medium
      transition: all ease-out .25s
      &.tt
        position: absolute
        left: 0
        top: 0
      &.mm
        position: absolute
        left: 0
        top: 8px
      &.bb
        position: absolute
        left: 0
        top: 16px
    &.open
      span
        width: 24px
        height: 2px
        background-color: $medium
        &.tt
          position: absolute
          left: 0
          top: 8px
          transform: rotate(45deg)
        &.mm
          width: 0
          position: absolute
          left: 12px
          top: 16px
          background-color: transparent
        &.bb
          position: absolute
          left: 0
          top: 8px
          transform: rotate(-45deg)
  +v-xs
    position: fixed
    top: 28px
    right: 56px
    display: block

.mobile
  +v-sm
    transform: translate(-4px,11px)
  .in
    +v-sm
      transform: translateY(1px)

.flex
  &.m-block
    +v-sm
      display: block
      margin: 0 !important
  &.sign
    img
      +v-sm
        display: inline-block
        margin: 4px !important

.m-none
  +v-sm
    display: none
.m-icon
  +v-sm
    height: 100px !important
    margin-bottom: 16px
    padding: 0
  +v-xs
    width: 48px !important
    height: 72px !important
    margin-bottom: 16px
    padding: 0
.m-30
  +v-sm
    margin-top: 30px
.m-pad
  +v-sm
    padding: 12px 24px !important
    margin-bottom: 30px
.m-bottom
  +v-sm
    padding-bottom: 20px !important
.m-40
  +v-sm
    padding-top: 40px !important

.accordion
  background-color: transparent !important
  .accordion-item
    border-radius: 0 !important
  *
    background-color: transparent !important
    border-left: none !important
    border-right: none !important
  .accordion-button:not(.collapsed)
    color: $dark !important
    background-color: transparent !important
