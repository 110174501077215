.title
  margin: 0
  padding: 0
  font-size: 2.7em
  font-weight: 500
  line-height: 1.25 !important
  +v-xs
    font-size: 1.2em
  &-mini
    font-size: 0.85em
  &-page
    font-size: 1.15em
    font-weight: 500
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
  &-sub
    font-size: 1.35em
    font-weight: 600
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
    &.w-60
      +v-xs
        margin: 0 auto
        min-width: 96% !important
    &.w-40
      +v-sm
        margin: 0 0 60px
        min-width: 100% !important

footer
  +v-sm
    margin-top: 80px
  a
    &:hover
      color: $dark !important
  .f
    &-text
      color: $footer
      font-size: 1em
      font-weight: 500
      line-height: 1.5

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important
